.wrapper {
  border: 1px solid var(--color-border-light);
  margin-top: var(--space-2);
}

.accordion {
  border: unset;
}

.summary {
  background: unset !important;
}

.header {
  font-weight: 700;
}

.details {
  padding-top: 0;
  padding-bottom: 12px;
}

.copy {
  padding: var(--space-2);
  padding-top: 4px;
}

.icon {
  margin-right: 4px;
}

.button {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
