.queueButton {
  display: flex;
  gap: var(--space-1);
  align-items: center;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background: transparent;
}

.isMobile {
  padding: 0 var(--space-2) var(--space-2);
}
