.guardDisplay {
  background-color: var(--color-secondary-background);
  border: 1px solid var(--color-success-dark);
  padding: 8px;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guardDisplay.warning {
  background-color: var(--color-warning-background);
  border-color: var(--color-warning-dark);
}

.guardDisplay.info {
  background-color: var(--color-info-background);
  border-color: var(--color-info-dark);
}
